<template>
  <div class="UpdateSubscription">

    <CModal
      :show.sync="isCompleteSubcriptionError"
      :no-close-on-backdrop="true"
      title="Erreur lors de la souscription"
      size="lg"
      color="dark"
    >
      Une erreur s'est produite lors de la validation de votre abonnement. Le paiement a toutefois bien été validé.
      Veuillez attendre quelques instants, puis tenter de vous reconnecter pour accéder à Eclerk.
      <template #footer>
        <CButton @click="isCompleteSubcriptionError = false" color="dark">Fermer</CButton>
      </template>
    </CModal>

    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>

    <loading
      :active.sync="validateSubscriptionInProcess"
      :can-cancel="false"
      :is-full-page="true"
      color="green">
    </loading>

    
    <CRow>
      <CCol>
        <h1>Finaliser votre abonnement</h1>
      </CCol>
    </CRow>
      
    
    
    <CCard class="mt-3" >
      <CCardBody>
          
        <CRow>
          <CCol>
            <p>
              Nous espérons que vous appréciez nos services. <strong>Vous pouvez maintenant souscrire un abonnement à Eclerk.</strong><br>
              Nous vous rappelons que cette abonnement est <strong> sans engagement</strong> et que votre compte sera conservé, même si vous 
              résiliez votre abonnement
            </p>
          </CCol>
        </CRow>
        
        <CRow class="mt-2">
          <CCol>
            <CInput
              id="cardholder-name"
              label="Nom du titulaire de la carte"
              autocomplete="eclerk-no-autocomplete-input"
              v-model="newCardHolderName" type="text"
              maxlength="200"
              placeholder="Nom du titulaire"
              @input="$v.newCardHolderName.$touch()"
              :isValid="$v.newCardHolderName.$dirty ? !$v.newCardHolderName.$error : null"
              invalid-feedback="Le nom doit comporter entre 2 et 200 caractères"
            >
            </CInput>
            <label>Votre numéro de carte bancaire</label>
            <div ref="card"></div>
            <small id="card-errors" class="text-danger"></small>
            <CButton
              :color="validateSubscriptionButtonStyle"
              shape="pill" block class="px-4 mt-4"
              @click="handleSubmit"
              :disabled="$v.newCardHolderName.$invalid || validateSubscriptionInProcess">
              {{ validateSubscriptionButtonText }}
            </CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    
    
    
    
  </div>
</template>

<script src="https://js.stripe.com/v3/"></script>
<script>
import { stripePublishableKey, stripeSoloPrice, stripeSmallPrice, stripeMediumPrice } from '@/variables/localVariables'
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

import { APIBossConnected } from '@/api/APIBossConnected'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiBossConnected = new APIBossConnected()

var stripe = Stripe(stripePublishableKey), elements = stripe.elements(), card = undefined;

export default {
  name: 'UpdateSubscription',
  components: {
    Loading,
  },
  mixins: [
    validationMixin,
  ],
  data: function () {
    return {

      // ---- COMPANY ---------
      isCompanyLoading: false,
      company: {

        company_name: '',
        siret: '',
        forme_sociale: '',
        capital: 0,
        address: '',
        phone_country_code: '',
        phone_number: '',
        email: '',
        iban: '',
        bic: '',
        titulaire: '',
        
        stripe_customer_id: '',
        stripe_subscription_id: '',
        stripe_product_id: '',
        stripe_price_id: '',
        stripe_payment_method_id: '',
        stripe_subscription_current_period_end: '',
        stripe_subscription_is_active: '',
        stripe_subscription_is_trial: '',
        stripe_subscription_trial_end: '',
      },

      // ---- PAIEMENT --------
      
      newCardHolderName: '',
      
      validateSubscriptionButtonText: 'Payer et valider votre abonnement',
      validateSubscriptionButtonStyle: 'outline-primary',
      validateSubscriptionInProcess: false,

      isCompleteSubcriptionError: false
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isCompanyLoading) {
        return true
      }
      return false
    },
  },
  mounted: function () {
    card = elements.create('card');
    card.mount(this.$refs.card);
    card.on('change', this.showCardError)
  },
  beforeDestroy() {
    card.destroy(this.$refs.card);
   },
  created: function() {
    this.getCompany()
  },
  
  validations: {
    newCardHolderName: {
      required,
      maxLength: maxLength(199),
      minLength: minLength(2)
    }
  },

  methods: {
    // ------------- Getters --------------
    getCompany () {
      this.isCompanyLoading = true
      apiBossConnected.getCompany(this.token)
      .then((result) => {
        this.company = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isCompanyLoading = false
      })
    },

    showCardError(event) {
      var displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    },

    // ------------- Stripe functions -----------





    //  NEED CUSTOMER ACTION
    handlePaymentThatRequiresCustomerAction({
      subscription,
      invoice,
      priceId,
      paymentMethodId,
      isRetry,
    }) {
      if (subscription && subscription.status === 'active') {
        // Subscription is active, no customer actions required.
        return { subscription, priceId, paymentMethodId };
      }

      // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
      // If it's a retry, the payment intent will be on the invoice itself.
      var paymentIntent = invoice ? invoice.payment_intent : subscription.latest_invoice.payment_intent;

      if (
        paymentIntent.status === 'requires_action' ||
        (isRetry === true && paymentIntent.status === 'requires_payment_method')
      ) {
        return stripe
          .confirmCardPayment(paymentIntent.client_secret, {
            payment_method: paymentMethodId,
          })
          .then((result) => {
            if (result.error) {
              // Start code flow to handle updating the payment details.
              // Display error message in your UI.
              // The card was declined (i.e. insufficient funds, card has expired, etc).
              throw result;
            } else {
              if (result.paymentIntent.status === 'succeeded') {
                // Show a success message to your customer.
                // There's a risk of the customer closing the window before the callback.
                // We recommend setting up webhook endpoints later in this guide.
                
                // On dit qu'on active la subscription pck le 3D secure a marché. Sinon c'est naze pck la subscription rest en "incomplete"
                if (subscription) {
                  subscription.status = 'active'
                }
                
                return {
                  priceId: priceId,
                  subscription: subscription,
                  invoice: invoice,
                  paymentMethodId: paymentMethodId,
                };
              }
            }
          })
      } else {
        // No customer action needed.
        return {
          priceId: priceId,
          subscription: subscription,
          invoice: invoice,
          paymentMethodId: paymentMethodId,
        };
      }
    },

    handleRequiresPaymentMethod({
      subscription,
      paymentMethodId,
      priceId,
    }) {
      if (subscription.status === 'active') {
        // subscription is active, no customer actions required.
        return { subscription, priceId, paymentMethodId };
      } else if (
        subscription.latest_invoice.payment_intent.status ===
        'requires_payment_method'
      ) {
        // Using localStorage to manage the state of the retry here,
        // feel free to replace with what you prefer.
        // Store the latest invoice ID and status.
        const now = new Date()
        const ttl = 1000*60*60*3
        const invoice_status_item = {
          value: subscription.latest_invoice.payment_intent.status,
          expiry: now.getTime() + ttl
        }

        localStorage.setItem('avocanet-stripe-latestInvoicePaymentIntentStatus', JSON.stringify(invoice_status_item))
        localStorage.setItem(
          'avocanet-stripe-latestInvoiceId',
           subscription.latest_invoice.id
        );
        throw { error: { message: 'Votre carte a été refusée, veuillez réessayer avec une carte valide.' } };
      } else {
        return { subscription, priceId, paymentMethodId };
      }
    },

    onSubscriptionComplete(result) {
      if (result && !result.subscription) {
        const subscription = { id: result.invoice.subscription };
        result.subscription = subscription;
        localStorage.removeItem('avocanet-stripe-latestInvoicePaymentIntentStatus')
        localStorage.removeItem('avocanet-stripe-latestInvoiceId')
        return apiBossConnected.completeSubscription(this.token, 'active',
          result.invoice.subscription.id, result.invoice.subscription.items.data[0].price.product,
          result.invoice.subscription.items.data[0].price.id, result.paymentMethodId, result.invoice.subscription.current_period_end
        )
        .then((result) => {
          const company = result.data
          this.$store.commit('SUBSCRIPTION_COMPLETE', company)
          
          // On redirige vers la page de succes
          this.$router.push('/abonnement/validation')
        })
        .catch((error) => {
          this.isCompleteSubcriptionError = true
        })
        .finally(() => {
          this.validateSubscriptionInProcess = false
        })
      }
      // Payment was successful.
      else if (result.subscription.status === 'active') {
        return apiBossConnected.completeSubscription(this.token, result.subscription.status,
          result.subscription.id, result.subscription.items.data[0].price.product,
          result.subscription.items.data[0].price.id, result.paymentMethodId, result.subscription.current_period_end
        )
        .then((result) => {
          const company = result.data
          this.$store.commit('SUBSCRIPTION_COMPLETE', company)
          
          // On redirige vers la page de succes
          this.$router.push('/abonnement/validation')

        })
        .catch((error) => {
          this.isCompleteSubcriptionError = true
        })
        .finally(() => {
          this.validateSubscriptionInProcess = false
        })
      }
    },

    updateSubscriptionWithFreeTrial({ customerId, paymentMethodId, priceId  }) {
      return apiBossConnected.updateSubscriptionWithFreeTrial(this.token, paymentMethodId)
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.data.error) {
            // The card had an error when trying to attach it to a customer.
            throw result.data
          }
          return result.data
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the addional details we need.
        .then((result) => {
          return {
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            subscription: result,
          }
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(this.handlePaymentThatRequiresCustomerAction)
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail, you
        // get a requires_payment_method error.
        .then(this.handleRequiresPaymentMethod)
        // No more actions required. Provision your service for the user.
        .then(this.onSubscriptionComplete)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          this.validateSubscriptionInProcess = false
          this.showCardError(error)
        })
    },

    retryInvoiceWithNewPaymentMethod({
      customerId,
      paymentMethodId,
      invoiceId,
      priceId
    }) {
      return apiBossConnected.retrySubscription(this.token, customerId, paymentMethodId, invoiceId)
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.data.error) {
            // The card had an error when trying to attach it to a customer.
            throw result.data
          }
          return result.data
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the addional details we need.
        .then((result) => {
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            invoice: result,
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            isRetry: true,
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(this.handlePaymentThatRequiresCustomerAction)
        // No more actions required. Provision your service for the user.
        .then(this.onSubscriptionComplete)
        .catch((error) => {
          this.validateSubscriptionInProcess = false
          this.showCardError(error)
        })
    },

    handleSubmit() {

      this.validateSubscriptionInProcess = true

      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return
      }

      // If a previous payment was attempted, get the lastest invoice
      const latestInvoicePaymentIntentStatusItemStr = localStorage.getItem(
        'avocanet-stripe-latestInvoicePaymentIntentStatus'
      )
      var latestInvoicePaymentIntentStatus = null

      if (latestInvoicePaymentIntentStatusItemStr) {
        const latestInvoicePaymentIntentStatusItemJson = JSON.parse(latestInvoicePaymentIntentStatusItemStr)
        const now = new Date()
        if (now.getTime() > latestInvoicePaymentIntentStatusItemJson.expiry) {
          localStorage.removeItem('avocanet-stripe-latestInvoicePaymentIntentStatus')
          localStorage.removeItem('avocanet-stripe-latestInvoiceId')
        }
        else {
          latestInvoicePaymentIntentStatus = latestInvoicePaymentIntentStatusItemJson.value
        }
      }


      return stripe
        .createPaymentMethod({
          type: 'card',
          card: card,
          billing_details: {
            name: this.newCardHolderName
          }
        })
        .then((result) => {
          if (result.error) {
            this.validateSubscriptionInProcess = false
            this.showCardError(result)
          } else {
            const paymentMethodId = result.paymentMethod.id
            if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
              // Update the payment method and retry invoice payment
              const invoiceId = localStorage.getItem('avocanet-stripe-latestInvoiceId');
              this.retryInvoiceWithNewPaymentMethod({
                customerId: this.company.stripe_customer_id,
                paymentMethodId: paymentMethodId,
                invoiceId: invoiceId,
                priceId: this.company.stripe_price_id
              });
            } else {
              // Create the subscription
              this.updateSubscriptionWithFreeTrial({
                customerId: this.company.stripe_customer_id,
                paymentMethodId: paymentMethodId,
                priceId: this.company.stripe_price_id
              })
            }
          }
        })
    },
  }
}
</script>
<style>

.tarif-card {
  height: 300px;
  
}

.chosen-tarif-card {
  cursor: pointer;
  box-shadow: rgba(46, 184, 92, 0.8) 2px 2px 5px !important;
}

.not-chosen-tarif-card {
  cursor: pointer;
  border-color: red;
}

.text-transparent {
  color: rgba(255, 255, 255, 0);
}


.price {
  font-size: 42px;
  font-weight: 600;
}

.chosenTarif {
  border-radius: 25px;
  background-color: #3c4b64;
  color: white;
}

.notChosenTarif {
  color: black;
  
}


.price-label {
    position: relative;
    margin: 0 auto 16px auto;
}
.price-label .price-label-centered-cols {
    display: flex;
    flex-flow: row;
    justify-content: center;
}

.price-label .hors_tax {
    padding-top: 12px;
    display: block;
    font-weight: 200;
    font-size: 24px;
}

.price-label .value {
    font-size: 36px;
    line-height: 86px;
    font-weight: 600;
}

.price-label .price-label-centered-cols .col-lines {
    display: flex;
    flex-flow: column;
    justify-content: center;
}
.text-left {
    text-align: left;
}

.price-label .tax {
    font-weight: 300;
    font-size: 14px;
    line-height: 13px;
}
</style>
